
.loginsubcontainer {
    align-items: center;
        display: flex;
        flex-grow: 1;
flex-shrink: 0;
}

.loginform {
  margin-left: 33.33333%;
      flex: none;
      width: 33.33333%;
      display: block;
    background: #eee;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    display: block;
    margin: 0 auto;
}

.loginform .loginButton.disabled {
  background-color: #233440 !important;
  color: #8a8a8a !important;
}


.loginform .loginButton {
  background-color: #4098D7;
text-transform: uppercase;
border-radius: 2px;
border-width: 2px;
line-height: 44px;
padding: 0 15px;
height: 48px;
width: 100%;
margin: 0;
color: #fff;
text-transform: uppercase;
border-radius: 2px;
border-width: 2px;
line-height: 44px;
padding: 0 15px;
height: 48px;
width: 100%;
margin: 0;
transition: all .5s cubic-bezier(.19,1,.22,1);
display: inline-block;
margin-bottom: 0;
font-weight: 400;
text-align: center;
vertical-align: middle;
-ms-touch-action: manipulation;
touch-action: manipulation;
cursor: pointer;
background-image: none;
border: 1px solid transparent;
white-space: nowrap;
font-size: 14px;
line-height: 1.42857;
border-radius: 4px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.loginform .loginButton input {
  background-color: transparent;
text-transform: uppercase;
border-radius: 2px;
border-width: 2px;
line-height: 44px;
padding: 0 15px;
height: 48px;
width: 100%;
margin: 0;
text-transform: uppercase;
border-radius: 2px;
border-width: 2px;
line-height: 44px;
padding: 0 15px;
height: 48px;
width: 100%;
margin: 0;
transition: all .5s cubic-bezier(.19,1,.22,1);
display: inline-block;
margin-bottom: 0;
font-weight: 400;
text-align: center;
vertical-align: middle;
-ms-touch-action: manipulation;
touch-action: manipulation;
cursor: pointer;
border: 1px solid transparent;
white-space: nowrap;
padding: 6px 12px;
font-size: 14px;
line-height: 1.42857;
border-radius: 4px;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.loginform .loginButton .SpinnerBox {
  padding-top: 10px;
}


.loginform .input-box {
      padding: 5px 0px;
}
.loginform .button-box {
      padding: 15px 0px;
}

.loginform .link-box {
  padding: 5px 0px;
  text-align: left;
}

.loginform .link-box a {
  cursor: pointer;
}

.loginform .Signin {
  padding: 1.75rem;
  text-align: center;
  border-top: 5px solid #4098D7;
}

.loginform .Signin form {
  margin-top: 20px;
}

.loginform .Signin-footer {
  background-color:white;
  padding: .75rem;
  text-align: center;
}

.loginform .Signup {
  padding: 1.75rem;
  text-align: center;
  border-top: 5px solid #4098D7;
}

.loginform .Signup .formInput {
  display: flex;
align-items: center;
margin-bottom: 20px;
}

.loginform .Signup .formInput input {
  margin: 0px;
}

.loginform .Signup .formInput img {
  width: 25px;
  margin-left: 10px;
}

.loginform .Signup .InvitedBy {

}

.loginform .Signup .InvitedBy img {
  width: 10px;
}

.loginform .Signup .SignUpCard {
  color: #000;
  display: inline-block;
  margin-bottom: 0;
  height: 220px;
  cursor: pointer;
  width: 230px;
}

.loginform .Signup .SignUpCard .SignUpCard_container {
  width: 100%;
}

.loginform .Signup .SignUpCard .SignUpCard_container .card_map {
  width: 100%;
border: 5px solid #fff;
height: 171px;
}

.loginform .Signup .SignUpCard .SignUpCard_container .card_map img {
  width: 220px;
height: 160px
}

.loginform .Signup form {
  margin-top: 20px;
}

.loginform .Signup .Signup__StepSummary {
margin-top: 40px;
margin-bottom: 40px;
}
.loginform .Signup .Signup__StepSummary .Signup__StepSummaryStep {
  margin-bottom: 20px;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.loginform .Signup .Signup__StepSummary .Signup__StepSummaryStep img {
  width: 14px;
  margin-right: 8px;
}
.loginform .Signup .Signup__StepSummary .Signup__StepSummaryStep p {
  margin: 0px;
}

.loginform .Signup-footer {
  background-color:white;
  padding: .75rem;
  text-align: center;
}

.loginform .PasswordReset {
  padding: 1.75rem;
  text-align: center;
  border-top: 5px solid #297cbb;
}

.loginform .PasswordReset form {
  margin-top: 20px;
}

.loginform .PasswordReset-footer {
  background-color:white;
  padding: .75rem;
  text-align: center;
}



@media only screen and (max-width: 700px) {
  .loginform {
    width: 95%;
  }
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}



.SeedPublisherProgress {
  z-index: 1;
width: 100%;
position: fixed;
}
.SeedPublisherBlockLoading {
  position: fixed;
      top: 50%;
      left: 50%;
      margin-top: -300px;
      margin-left: -250px;
      z-index: 3;
}
.SeedPublisherBlockLoading .sk-double-bounce {
  width: 500px !important;
  height: 500px !important;
}
nav.SeedPublisherNav {
  background-color: #fafafa;
padding: 0;
background-color: #fff;
border-bottom: 1px solid rgba(0,0,0,.0975);
position: fixed;
top: 0;
width: 100%;
z-index: 3;
-webkit-transition: height .2s ease-in-out;
transition: height .2s ease-in-out;
height: 77px;
border-top: 2px solid #edededa1;
}

nav.SeedPublisherNav .SeedPublisherNav__container {
  margin-left:auto;
  margin-right: auto;
  width: 1000px;
  display: -ms-flexbox;
    display: flex;
    display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
height: 100%;
}

nav.SeedPublisherNav .SeedPublisherNav__infomap {
flex: 0 0 auto;
}
nav.SeedPublisherNav .SeedPublisherNav__infomap h2 {
 color: black;
 margin: 0px;
 font-size: 24px;
}
nav.SeedPublisherNav .SeedPublisherNav__infomap h5 {
 color: black;
 margin: 5px 0px;
 font-size: 14px;
 color: #6c7672a1;
}

nav.SeedPublisherNav .SeedPublisherNav__logo {
flex: 1 0 auto;
    text-align: right;
}

nav.SeedPublisherNav .SeedPublisherNav__logo img {
width: 90px;
}

.SeedPublisher {
  width: 100%;
background-color: #EEEFEE;
z-index: 2;
position: absolute;
    top: 260px;
    overflow: auto;
    margin-bottom: 90px;
    padding-bottom: 50px;
}

.SeedPublisher__header {
  text-align: center;
}

.SeedPublisher__header-container {
  border-bottom: 2px solid #6c767247;
      padding: 20px;
}

.SeedPublisher__header-container h2 {
  font-size: 24px;
}

.SeedPublisher__header-container h5 {
  font-size: 14px;
  color: #6c7672a1;
}

.SeedPublisher__content {
  padding: 25px;
  max-width: 1000px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  opacity: 0.2;
}

.SeedPublisher__content.active {
  opacity: 1;
}

.SeedPublisher__footer {
  position: fixed;
  bottom: 0;
  height: 90px;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.0975);
  background-color: #fafafa;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
padding: 0px 30px;
z-index: 2;
}
.SeedPublisher__footer .SeedPublisher__footer-item {

}
.SeedPublisher__footer .SeedPublisher__footer-item--desc {
  flex: 1 1 auto;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}
.SeedPublisher__footer .SeedPublisher__footer-item--desc input {
}
.SeedPublisher__footer .SeedPublisher__footer-item--cta {
  flex: 1 1 auto;
    text-align: right;
}

.SeedPublisher__footer .button-box a.primary {
  background-color: white;
}



.SeedPublisher__tabs {
padding: 15px 20px 5px 20px;
}
.SeedPublisher__tabs .SeedPublisher__tabContainer {
      border-bottom: 1px solid gray;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab {
  display: inline-block;
      padding: 10px;
          cursor: pointer;
border-bottom: 0px;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab span {
  text-transform: uppercase;
  color: #6C7672;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab:hover {
  border-bottom: 1px solid black;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab:hover span {
  color: black;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab .SeedPublisher__tab-name {

}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab.SeedPublisher__tab--selected {
  border-bottom: 1px solid black;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab.SeedPublisher__tab--selected span {
  color: black;
}

.SeedPublisher__tabs .SeedPublisher__tabContainer .SeedPublisher__tab.SeedPublisher__tab--selected .SeedPublisher__tab-name {

}

.FSMapContainer__popover {
position: fixed;
top: 77px;
width: 100%;
}
.FSMapContainer .FSMapContainer__popover .FSMapContainer__popoverContainer{
  padding: 20px;
  max-width: 1000px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.StepBox {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24);
  box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24);
  background-color: #e9eaec;
}
.StepBox .StepBox__container {
  display: -ms-flexbox;
display: flex;
}

.StepBox .StepBox__container .StepBox__step {
  background-color: #fafafa;
  padding: 10px;
  flex: auto;
  border: 1px solid #D9E2EC;
  border-radius: 3px;
}
.StepBox .StepBox__container .StepBox__step .StepBox__step-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.StepBox .StepBox__container .StepBox__step .StepBox__stepAccessory {
  margin-right: 10px;
}
.StepBox .StepBox__container .StepBox__step .StepBox__stepAccessory img {
  width: 13px;
}

.StepBox .StepBox__container .StepBox__step .StepBox__stepDetails {
}

.StepBox .StepBox__container .StepBox__step .StepBox__stepDetails span.status {
  display: block;
  font-weight: 500;
  color: #102A43;
}
.StepBox .StepBox__container .StepBox__step .StepBox__stepDetails span.title {
  display: block;
  color: #486581;
}


.StepBox .StepBox__container .StepBox__step.completed {
  background-color: #F0F4F8;
}
.StepBox .StepBox__container .StepBox__step.completed .StepBox__step-container {
}
.StepBox .StepBox__container .StepBox__step.completed .StepBox__stepAccessory {
}

.StepBox .StepBox__container .StepBox__step.completed .StepBox__stepAccessory img.completed {
}

.StepBox .StepBox__container .StepBox__step.completed .StepBox__stepDetails {
}

.StepBox .StepBox__container .StepBox__step.completed .StepBox__stepDetails span.status {
  color: #102A43;
}
.StepBox .StepBox__container .StepBox__step.completed .StepBox__stepDetails span.title {
  color:#102A43;
}


.StepBox .StepBox__container .StepBox__step.disabled {
  background-color: #F9FAFC;
}
.StepBox .StepBox__container .StepBox__step.disabled .StepBox__step-container {
  opacity: 0.5;
}
.StepBox .StepBox__container .StepBox__step.disabled .StepBox__stepAccessory {
}

.StepBox .StepBox__container .StepBox__step.disabled .StepBox__stepDetails {
}

.StepBox .StepBox__container .StepBox__step.disabled .StepBox__stepDetails span.status {
  color: #4D5158;
}
.StepBox .StepBox__container .StepBox__step.disabled .StepBox__stepDetails span.title {
  color:#4D5158;
}

.LocationStep {
  border-radius: 2px;
  -webkit-box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24);
  box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.24);
margin-top: 30px;
}
.LocationStep .LocationStep__container {
  padding: 20px;
  max-width: 1000px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  border-radius: 3px;
}
.LocationStep .LocationStep__container .LocationStep__description {

}
.LocationStep .LocationStep__container .LocationStep__textField {
  background-color: white;
  margin-top: 30px;
display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
}

.LocationStep .LocationStep__container .LocationStep__textField input {
  margin-bottom: 6px;
}

.LocationStep .LocationStep__container .LocationStep__selectField {
  background-color: white;
  margin-top: 30px;
}
.LocationStep .LocationStep__container .LocationStep__selectField select {
  display: block;
}
.LocationStep .LocationStep__container .LocationStep__textFieldResults__container {
  background-color: white;
  margin-top: 30px;
}
.LocationStep .LocationStep__container .LocationStep__textFieldResults__container h6.LocationStep__textFieldResultsTitle {
  color: #000;
  font-weight: bold;
  margin-top: 35px;
margin-bottom: 22px;
}
.LocationStep .LocationStep__container .LocationStep__textFieldResults__container ul.LocationStep__textFieldResults {
  cursor: pointer;
  background-color: #f5f5f5;
    margin: 0px;
}
.LocationStep .LocationStep__container .LocationStep__textFieldResults__container ul.LocationStep__textFieldResults li {
  padding: 20px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
border-radius: 2px;
}
.LocationStep .LocationStep__container .LocationStep__textFieldResults__container ul.LocationStep__textFieldResults li:hover {
  background-color: #62B0E8;
  color: white;
}

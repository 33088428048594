@media only screen and (max-width: 700px) {
  .GeoSeeds__content-group {
    display: block !important;
  }

  .GeoSeeds__content-group--top-drawer .GeoSeeds__content-desc {
      display: initial !important;
  }

  .GeoSeeds__content-group .GeoSeeds__content-desc {
      margin-left:15px !important;
      margin-right:15px !important;
      min-height: 50px !important;
  }
  .GeoSeeds__content-group .GeoSeeds__content-box {
    padding-bottom: 10px;
  }
  .GeoSeeds__content-group .GeoSeeds__content-box .GeoSeeds__content-box-content {
    width: 213px;
  }
  .GeoSeeds__content-group .GeoSeeds__content-box .GeoSeeds__content-box-content span {
    word-wrap: break-word;
  }
  .GeoSeeds__content-group .GeoSeeds__content-box .GeoSeeds__content-box-content .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container {
  }
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

.GeoSeeds .GeoSeeds__DataTable {
  margin-top: 4px;
}

.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem {
  display: -ms-flexbox;
display: flex;
border-bottom: 2px solid #F7F7F7;
-ms-flex-align: center;
align-items: center;
padding-bottom: 10px;
padding-top: 15px;
padding-left: 10px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem.alt1 {
    background-color: #f7f7f763;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem:hover {
    background-color: #bbbbbb1c;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem.alt1:hover {
    background-color: #bbbbbb1c;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-title {
  width: 26%;
min-width: 200px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data {
  flex: auto;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data ul {
  margin: 0px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data ul .DataBox {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
margin-right: 15px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data ul .DataBox .cell {
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data ul li:not(:only-of-type):not(:last-child) {
  margin-bottom: 20px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data ul .DataBox .trash{
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: right;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__singleDataItem-data ul .DataBox .trash img{
  width: 13px;
}

.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem {
  display: -ms-flexbox;
display: flex;
border-bottom: 2px solid #F7F7F7;
padding-bottom: 10px;
padding-top: 15px;
padding-left: 10px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem.alt1 {
background-color: #f7f7f763;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem:hover {
    background-color: #bbbbbb1c;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem.alt1:hover {
    background-color: #bbbbbb1c;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-title {
  width: 26%;
min-width: 200px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-trash {
  flex: 1 1 auto;
      text-align: right;
      margin-right: 15px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-trash img {
      width: 13px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data {
  -ms-flex: auto;
  flex: auto;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data ul {
  margin: 0px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data ul:not(:last-child) {
  padding-bottom:10px;
  border-bottom: 1px solid #F6F6F6;
  margin-bottom: 10px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data ul .DataBox {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
margin-right: 15px;
    min-width: 227px;
    margin-bottom: 5px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data ul .DataBox .cell{
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data ul .DataBox .cell:first-child{
  padding-right: 15px;
  font-size: 14px;
font-weight: bold;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__multipleDataItem-data ul .DataBox .cell-2 {
  flex: auto;
text-align: right;
}
.GeoSeeds .GeoSeeds__DataTable tr.header {
   border-bottom: 2px solid #edededa1;
   font-size: 1em;
    font-weight: bold;
}

.GeoSeeds .GeoSeeds__DataTable td {
  padding: 10px 23px 10px 4px;
}

.GeoSeeds .GeoSeeds__content-group--top-drawer {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 4px solid #2c3033;
  background-color: #D9E2EC;
  border-right: 1px solid #BEBFBE;
  border-left: 1px solid #BEBFBE;
  box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  -webkit-box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  display: -ms-flexbox;
display: flex;
margin-bottom: 30px;
padding-right: 26px; /*the lifecyle select was running off the page?*/
}

.GeoSeeds .GeoSeeds__content-group--top-drawer .GeoSeeds__content-desc {
  padding: 20px;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.GeoSeeds .GeoSeeds__content-group--top-drawer .GeoSeeds__content-desc select {
  display: -ms-flexbox;
    display: flex;
    color: #000;
}

.GeoSeeds .GeoSeeds__content-group--top-drawer .GeoSeeds__content-desc .button-box {
  display: -ms-flexbox;
  text-align: right;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: right;
}

.GeoSeeds .GeoSeeds__content-group--top-drawer .GeoSeeds__content-desc .title {
  color: #102A43;
  display: -ms-flexbox;
    display: flex;
  flex: 1 1 auto;
  font-size: 1.14rem;
  min-width: 120px;
}

.GeoSeeds .GeoSeeds__content-group--delete {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: -ms-flexbox;
display: flex;
}

.GeoSeeds .GeoSeeds__content-group--delete .button-box{
  flex: 1 1 auto;
  text-align: right;
}
.GeoSeeds .GeoSeeds__content-group--delete .button-box .button {
      margin-right: 0px;
}

.GeoSeeds .GeoSeeds__content-group--alert {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-top: 4px solid #000;
  background-color: #F0B429;
  border-right: 1px solid #BEBFBE;
  border-left: 1px solid #BEBFBE;
  box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  -webkit-box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  display: -ms-flexbox;
display: flex;
margin-top: 20px;
margin-bottom: 30px;
}

.GeoSeeds .GeoSeeds__content-group--alert .GeoSeeds__content-desc {
  padding: 20px;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.GeoSeeds .GeoSeeds__content-group--alert .GeoSeeds__content-desc select {
  display: -ms-flexbox;
    display: flex;
    color: #000;
}

.GeoSeeds .GeoSeeds__content-group--alert .GeoSeeds__content-desc .button-box {
  display: -ms-flexbox;
  text-align: right;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: right;
}

.GeoSeeds .GeoSeeds__content-group--alert .GeoSeeds__content-desc .title {
  color: #f5f5f5;
  display: -ms-flexbox;
    display: flex;
  flex: 1 1 auto;
}



.GeoSeeds .GeoSeeds__content-group--info {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-top: 4px solid #000;
  background-color: #F0B429;
  border-right: 1px solid #BEBFBE;
  border-left: 1px solid #BEBFBE;
  box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  -webkit-box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  display: -ms-flexbox;
display: flex;
margin-top: 20px;
margin-bottom: 30px;
}

.GeoSeeds .GeoSeeds__content-group--info .GeoSeeds__content-desc {
  padding: 20px;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
}

.GeoSeeds .GeoSeeds__content-group--info .GeoSeeds__content-desc select {
  display: -ms-flexbox;
    display: flex;
    color: #000;
}

.GeoSeeds .GeoSeeds__content-group--info .GeoSeeds__content-desc .button-box {
  display: -ms-flexbox;
  text-align: right;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: right;
}

.GeoSeeds .GeoSeeds__content-group--info .GeoSeeds__content-desc .title {
  color: #f5f5f5;
  display: -ms-flexbox;
    display: flex;
  flex: 1 1 auto;
}

.GeoSeeds.GeoSeeds--full {
}
.GeoSeeds.GeoSeeds--full .GeoSeeds__content-group .GeoSeeds__content-desc {
  min-width: 14%;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 4px;
}
.GeoSeeds.GeoSeeds--full .GeoSeeds__content-group .GeoSeed__cta {
  margin-right: 10px;
}

.GeoSeeds {
}

.GeoSeeds__controls {
  z-index: 2;
  position: fixed;
  top: 15px;
  width: 40px;
  padding-left: 20px;
  opacity: 0.8;
}

.GeoSeeds__controls img {
  cursor: pointer;
}

.GeoSeeds__DocumentList {
    overflow-y: scroll;
    background-color: #F7F9FD;
}

.GeoSeeds .GeoSeeds__paddingTop {
  height: 66px;
  width: 100%;
  background-color: #f1f3f5;
  border-bottom: 1px solid #BEBFBE;
  margin-bottom:0px;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .GeoSeeds .GeoSeeds__breadcrumbs {
    font-size: 0.8em !important;
  }
}

.GeoSeeds .GeoSeeds__breadcrumbs {
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #EEF0F4;
    background-color: #F7F9FD;
}
.GeoSeeds .GeoSeeds__breadcrumbs .GeoSeeds__breadcrumb {
  margin-right: 10px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Lato', sans-serif;
}
.GeoSeeds .GeoSeeds__breadcrumbs .GeoSeeds__breadcrumb.GeoSeeds__breadcrumb-active {
  color: #8A8C92;
}

.GeoSeeds .GeoSeeds__header {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
width: 95%;
margin-left: auto;
margin-right: auto;
margin-top: 20px;
margin-bottom: 25px;
}

.GeoSeeds .GeoSeeds__footer {
  height: 90px;
  width: 100%;
}

.GeoSeeds .GeoSeeds__header .GeoSeeds__header-accessory {
  margin-right: 10px;
}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-accessory img {
  width: 50px;
}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-title {
  margin-right: 10px;

}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-twiddle {
  flex: 1 1 auto;
    text-align: right;
    float: right;
}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-twiddle .GeoSeeds__header-twiddle-group {
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    float: right;
}

.GeoSeeds__header-title-group {
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}
.GeoSeeds__header-title-group .GeoSeeds__header-title-group-item {
  margin-right: 11px;
}
.GeoSeeds__header-title-group .GeoSeeds__header-title-group-item .GeoSeeds__header-title-group-item-edit-title {
  padding: 10px;
    margin: 10px;
    height: 53px;
    border-top: 4px solid #000;
    border-right: 1px solid #BEBFBE;
    border-left: 1px solid #BEBFBE;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
    -webkit-box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
    display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
border-radius: 5px;
}
.GeoSeeds__header-title-group .GeoSeeds__header-title-group-item .GeoSeeds__header-title-group-item-edit-title input {
  width: 300px;
  margin: 0px;
  height: 27px;
  border-radius: 3px;
}
.GeoSeeds__header-title-group .GeoSeeds__header-title-group-item .GeoSeeds__header-title-group-item-edit-title img {
  width: 30px;
  margin-left: 15px;
}
.GeoSeeds__header-twiddle-group .GeoSeeds__header-twiddle-group-item .GeoSeeds__header-badge {
  background-color: #039be5;
    border-radius: 20px;
    color: white;
    /* left: 0px; */
    /* float: right; */
    margin-left: 34px;
    margin-top: -50px;
    padding: 0px 5px;
    -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.GeoSeeds__header-twiddle-group .GeoSeeds__header-twiddle-group-item .GeoSeeds__header-badge:hover {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
}
.GeoSeeds__header-twiddle-group .GeoSeeds__header-twiddle-group-item .GeoSeeds__header-photos {
  cursor: pointer;
  margin-top: -20px;
}

.GeoSeeds__header-twiddle-group .GeoSeeds__header-twiddle-group-item .GeoSeeds__header-photos img {
  width: 40px;
}
.GeoSeeds__header-twiddle-group .GeoSeeds__header-twiddle-group-item .GeoSeeds__header-photos img:hover {
  width: 41px;
}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-title h3 {
  margin-left: 18px;
}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-title h5 {
  font-size: 1em;
  margin-left: 18px;
  color: #6C7672;
}
.GeoSeeds .GeoSeeds__header .GeoSeeds__header-title h5 img {
  width: 10px;
margin-right: 2px;
}
.GeoSeeds .GeoSeeds__content-group {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  border-top: 4px solid #3F5A76;
  background-color: white;
  border-right: 1px solid #BEBFBE;
  border-left: 1px solid #BEBFBE;
  box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  -webkit-box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
  display: -ms-flexbox;
display: flex;
margin-top: 20px;
margin-bottom: 30px;
}

.GeoSeeds .GeoSeeds__content-loader {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  display: -ms-flexbox;
display: flex;
margin-top: 57px;
margin-bottom: 30px;
}
.GeoSeeds .GeoSeeds__content-loader .sk-spinner {
  margin-left: auto;
margin-right: auto;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-desc {
  flex: 0;
    margin-left: 25px;
    margin-right: 50px;
    min-width: 20%;
    min-height: 120px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-desc p {
  color: #AAAAAA;
  font-size: 0.9em;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-desc .GeoSeeds__content-desc-header {
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 12px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-desc .GeoSeeds__content-desc-header h4 {
  font-size: 1.3em;
  font-weight: bold;
  margin: 0px 7px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-desc .GeoSeeds__content-desc-header img {
  width: 15px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box {
  flex: 1 1 auto;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-header {
  border-bottom: 2px solid #edededa1;
height: 30px;
padding-left: 31px;
padding-right: 21px;
display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-header h5 {
  font-size: 1em;
  font-weight: bold;
  flex: 1 1;
  margin-left: 10px;
margin-right: 8px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-content {
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-cta {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
margin: 20px 9px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-cta.disabled {
opacity: 0.3;
pointer-events: none;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-cta img {
  width: 10px;
}
.GeoSeeds .GeoSeeds__content-group .GeoSeeds__content-box-cta h5 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: .90em;
  margin: 0px 5px;
  color: black;
}

.GeoSeeds .GeoSeeds__header a {
  cursor: pointer;
}
.GeoSeeds .GeoSeeds__header h3 {
  font-size: 1.4em;
  -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-right: 5px;
    margin-top: 18px;
    margin-left: 5px;
}

.GeoSeeds .GeoSeeds__header .button-box {
      margin-left: 10px;
}

.GeoSeeds .GeoSeeds__contents {
margin:10px 0px;
}

.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item {
  padding: 0px 10px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item:hover {
  background-color: #F7F9FD;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 10px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-content {
    flex: 1 1 auto;
    width: 60%;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-content .GeoSeed__item-content-display span {
word-wrap: break-word;
}
@media only screen and (max-width: 700px) {
  .GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__cta {
      margin-right: 0px !important;
  }
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__cta {
    margin-right: 26px;
    display: block;
    cursor: pointer;
    flex: 1 1 auto;
text-align: right;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__cta a {
  padding: 5px 20px;
    background-color: white;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config {
  flex: 1 1 auto;
text-align: right;
margin-top: 8px;
margin-right: 10px;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config.disabled {
  opacity: 0.2;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config-dropdown-container {
  display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config-dropdown.GeoSeed__config-dropdown--active {
    display: block;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config-dropdown {
  background-color: white;
      margin: 0;
      padding: 5px 0;
      min-width: 300px;
      list-style-type: none;
      display: none;
      position: absolute;
      top: 31px;
      float: right;
      right: 0px;
      font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
    border-radius: 4px;
    outline: none;
        z-index: 1;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config-dropdown li a {
  display: block;
  padding: 6px 20px 6px 13px;
  white-space: nowrap;
  text-align: left;
  background-color: transparent;
  color: #2895F1;
text-decoration: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
cursor: pointer;
}
.GeoSeeds .GeoSeeds__DataTable .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__config-dropdown li a:hover {
  background-color: #E8F2FB;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item:hover {
  background-color: #F7F9FD;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item:hover .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__cta {
    display: block;
}

.GeoSeeds .GeoSeeds__contents .GeoSeed__item.GeoSeed__item--detail {

}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-avatar a {
  width: 40px;
      height: 40px;
      background-color: #fafafa;
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      overflow: hidden;
      position: relative;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-avatar a img {
  height: 100%;
width: 100%;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper {
  width: 100%;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__item-avatar {

}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__item-content {
flex: 1;
margin-left:10px;
margin-right:8px;
cursor: default;
font-family: 'Lato', sans-serif;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__item-checkbox input {
  position: relative;
    left: 0px;
    opacity: 1;
}

.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__cta {
  padding: 5px 20px;
    background-color: white;
    margin-right: 26px;
    display: block;
    cursor: pointer;

}

.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__config {
  text-align: right;
  cursor: pointer;
  margin-right: 5px;
}

.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__config img {
  vertical-align: middle;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__config-dropdown.GeoSeed__config-dropdown--active {
    display: block;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__config-dropdown {
  background-color: white;
      margin: 0;
      padding: 5px 0;
      min-width: 300px;
      list-style-type: none;
      display: none;
      position: absolute;
      top: 50px;
      float: right;
      right: 0px;
      font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
    border-radius: 4px;
    outline: none;
        z-index: 1;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__config-dropdown li a {
  display: block;
  padding: 6px 20px 6px 13px;
  white-space: nowrap;
  text-align: left;
  background-color: transparent;
  color: #2895F1;
text-decoration: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
cursor: pointer;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .GeoSeed__config-dropdown li a:hover {
  background-color: #E8F2FB;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .actions {

}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .actions a {
  line-height: 32px;
    margin-right: 16px;
    font-size: 14px;
    padding: 0 16px;
    height: 32px;
    color: #1B2733;
    border-color: #C1C7CD;
    background: #fff;
    white-space: nowrap;
}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .details {

}
.GeoSeeds .GeoSeeds__contents .GeoSeed__item .GeoSeed__item-wrapper .GeoSeed__item-wrapper-container .details .alert {

}


.asset-type.attributes tr.selected {
  color: #039be5;
}
.asset-type.attributes tr {
  cursor: pointer;
}

.file-type.attributes tr.selected {
  color: #039be5;
}
.file-type.attributes tr {
  cursor: pointer;
}

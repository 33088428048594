.DesktopFooter {
  background-color: transparent;
  position: absolute;
  bottom: 10px;
  z-index: 2;
  left: 50%;
  width: 68px;
  margin-left: -34px;
}
.DesktopFooter .DesktopFooter__container {
}

.DesktopFooter .DesktopFooter__container .menu {
  border-radius: 45px;
    background-color: #4098D7;
    height: 68px;
    width: 68px;
    -webkit-transition: .3s ease-out;
    -o-transition: .3s ease-out;
    transition: .3s ease-out;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    padding-top: 13px;
    cursor: pointer;
}
.DesktopFooter .DesktopFooter__container .menu:hover {
  background-color: #243B53 !important;
}

.DesktopFooter .DesktopFooter__container .menu img {
  width: 34px;
margin-left: 17px;
margin-top: 5px;
color: white;
}

.DesktopFooter .DesktopFooter__container .menu svg {
  width: 34px;
margin-left: 17px;
margin-top: 5px;
color: white;
}

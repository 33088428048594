
.PropertyMiniPopup {
  margin-left: 10px;
}

.PropertyMiniPopup .PropertyMiniPopup__header {
width: 100%;
}

.PropertyMiniPopup .PropertyMiniPopup__header .PropertyMiniPopup__cta {
  float:right;
}

.PropertyMiniPopup .PropertyMiniPopup__header .PropertyMiniPopup__cta button {
  font-size: 14px;
border: 1px solid #0091E1;
border-radius: 25px;
font-weight: 500;
padding: 3px 10px;
color: #0091E1;
background-color: white;
}

.PropertyMiniPopup .PropertyMiniPopup__header .PropertyMiniPopup__cta button:hover {
  background-color: #297cbb1f;
}

.PropertyMiniPopup .PropertyMiniPopup__header .PropertyMiniPopup__cta button:focus {
  background-color: #EEEFEE;
}

.PropertyMiniPopup .PropertyMiniPopup__header h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  margin: 10px 0px;
}

.PropertyMiniPopup .PropertyMiniPopup__header h5 {
  font-size: 14px;
  text-align: left;
}

.PropertyMiniPopup .PropertyMiniPopup__header img {
  width: 10px;
}

.PropertyMiniPopup .PropertyMiniPopup__content {
  text-align: left;
  margin: 20px 0px;
}
.PropertyMiniPopup .PropertyMiniPopup__content p {
  font-size: 14px;
}

.PropertyMiniPopup .PropertyMiniPopup__footer {
  border-top: 2px solid #edededa1;
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.PropertyMiniPopup .PropertyMiniPopup__footer-item {
width: 125px;
height: 80px;
text-align:left;
padding-top: 10px;
}

.PropertyMiniPopup .PropertyMiniPopup__footer h2 {
  font-size: 20px;
  font-weight: 500;
margin: 0px;
}

.PropertyMiniPopup .PropertyMiniPopup__footer h5 {
  font-size: 14px;
  color: #6c7672a1;
}

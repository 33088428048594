@media only screen and (max-width: 700px) {
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

.Home {
  background-color: #F0F4F8;
}
.Home__nav {
  height: 100px;
}
.Home__mainPanel {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.Home__breadcrumb {
    display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
}

.Home__breadcrumb .Home__breadcrumbWrapper {
    display: contents;
}

.Home__breadcrumb .Home__breadcrumbWrapper a {
  color: #637282;
}

.Home__breadcrumb .Home__breadcrumbWrapper img {
  width: 24px;
  height: 24px;
}

.Home__masterPane table {
  font-family: 'AtlasGrotesk', sans-serif;
    color: #1b2733;
    -webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.Home__masterPane table thead {
  z-index: 1;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
}

.Home__masterPane table tbody {
  -ms-flex-direction: column;
    flex-direction: column;
    display: -ms-flexbox;
display: flex;
}

.Home__masterPane table tbody tr {
      cursor: pointer;
      z-index: auto;
border-left: 1px solid transparent;
border-right: 1px solid transparent;
    border-bottom: 1px solid #9FB3C8;
    background: #FFF;
    display: -ms-flexbox;
    display: flex;
    min-height: 64px;
    margin-bottom: 20px;
    color: #334E68;
    box-shadow:0 4px 6px #D9E2EC;
}
.Home__masterPane table tbody tr:active {
  box-shadow: 0 1px 3px #D9E2EC;
}
.Home__masterPane table tbody tr td {

}
.Home__masterPane table tbody tr td .cell {
  vertical-align: center;
}
.Home__masterPane table tbody tr td .cell h5 {
  font-size: 14px;
}


.Home__placePane table {
  font-family: 'AtlasGrotesk', sans-serif;
    color: #1b2733;
    -webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
}

.Home__placePane table thead {
  z-index: 1;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-orient: vertical;
-webkit-box-direction: normal;
-ms-flex-direction: column;
flex-direction: column;
}

.Home__placePane table tbody {
  -ms-flex-direction: column;
    flex-direction: column;
    display: -ms-flexbox;
display: flex;
}

.Home__placePane table tbody tr {
      cursor: pointer;
      z-index: auto;
border-left: 1px solid transparent;
border-right: 1px solid transparent;
    border-bottom: 1px solid #F0F4F8;
    background: #FFF;
    display: -ms-flexbox;
    display: flex;
    min-height: 64px;
    color: #334E68;
    box-shadow:0 4px 6px #D9E2EC;
}
.Home__placePane table tbody tr:active {
  box-shadow: 0 1px 3px #D9E2EC;
}
.Home__placePane table tbody tr td {

}
.Home__placePane table tbody tr td .cell {
  vertical-align: center;
}
.Home__placePane table tbody tr td .cell h5 {
  font-size: 14px;
}


.Home__tabContainer {
  border-bottom: 1px solid #334E68;
      margin-bottom: 40px;
      width: calc(100% - 560px);
min-width: 464px;
height: auto;
margin-top: 0;
line-height: 39px;
padding-left: 0;
list-style: none;
-webkit-font-smoothing: antialiased;
border-bottom: 1px solid rgba(61,70,77,0.1);
}

.Home__tabContainer .Home__tabContainerItem {
      display: inline-block;
          padding: 0px 10px 0px 10px;
              cursor: pointer;
  border-bottom: 0px;
margin-bottom: -1px;
border-bottom: 1px solid transparent;
color: #7b8994;
-webkit-transition: all 0.2s;
transition: all 0.2s;
-webkit-transition-property: color, border;
transition-property: color, border;
text-align: center;
white-space: nowrap;
}

.Home__tabContainer .Home__tabContainerItem span {
  text-transform: uppercase;
  color: #6C7672;
}

.Home__tabContainer .Home__tabContainerItem:hover {
}

.Home__tabContainer .Home__tabContainerItem:hover span {
color: #334E68;
}

.Home__tabContainer .Home__tabContainerItem--selected {
  border-bottom: 1px solid #003E6B !important;
  display: inline-block;
      padding: 0px 10px 0px 10px;
          cursor: pointer;
border-bottom: 0px;
font-weight: 500;
color: #003E6B;
}

.Home__tabContainer .Home__tabContainerItem--selected span {
  text-transform: uppercase;

color: #003E6B;
}

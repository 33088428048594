

.Profile {
  width: 100%;
height: 100%;
background-color: #fafafa;
z-index: 3;
position: fixed;
    left: 0px;
    top: 0px;
}


.Profile__content {
  padding-right: 20px;
      padding-left: 20px;
      margin-right: auto;
      margin-left: auto;
      max-width: 640px;
}

.Profile__content--body {
  margin-top: 100px;
}

.Profile__content--input {

}

.Profile__content--input span {

}

.Profile__content--input input {

}



.Profile__footer {
  position: fixed;
  bottom: 0;
  height: 90px;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.0975);
  background-color: #fafafa;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
padding: 0px 30px;
}
.Profile__footer .Profile__footer-item {

}
.Profile__footer .Profile__footer-item--desc {
  flex: 1 1 auto;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
}

.Profile__footer .Profile__footer-item--desc img {
  width: 40px;
}
.Profile__footer .Profile__footer-item--desc input {
  margin: 0px 20px
}

.Profile__footer .Profile__footer-item--cta {
  flex: 1 1 auto;
    text-align: right;
}

.Profile__footer .button-box a.primary {
  background-color: white;
}

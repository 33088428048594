.FilePublisher {
  margin-top: 50px;
}

.FilePublisher .FilePublisher__complete {

}

.FilePublisher .FilePublisher__complete img {
  width: 200px;
}

.dropzone {
  width: 100%;
    height: 324px;
    border-width: 2px;
    border-color: #6c767247;
    border-style: dashed;
    border-radius: 5px;
    text-align: center;
}

.dropzone .panel {
  margin-top: 40px;
}

.dropzone p {
  text-align: center;
  color: #87949D;
  margin-bottom: 20px;
}

.dropzone h3 {
  color: #87949D;
}

.dropzone button {
  padding: 10px 15px 10px 15px;
    border: 1px solid #3F96D7;
    border-radius: 4px;
    color: #3F96D7;
    font-size: 18px;
    font-weight: bold;
}

.dropzone img {
  width: 52px;
}

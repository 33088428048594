.BookmarkSearch {
  margin-bottom: 20px;
  max-width: 935px;
  margin-left: auto;
  margin-right: auto;
}

.BookmarkSearch .BookmarkSearch__inputContainer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ECEDF0;
  border: 0px !important;
  padding: 10px 20px;
  border-radius: 10px;
  color: #A0A4A7;
}

.BookmarkSearch .BookmarkSearch__inputContainer img {
  width: 25px;
}

.BookmarkSearch .BookmarkSearch__inputContainer input {
    border: 0px !important;
    color: #A0A4A7;
    margin: 0px 0px 0px 17px;
}
.BookmarkSearch .BookmarkSearch__inputContainer input:focus {
    border: 0px !important;
}

@media only screen and (max-height: 700px) {
  .CardDeckNav__close {
    position: initial !important;
  }
  .CardDeckNav__close .menu {
    height: 38px !important;
    width: 38px !important;
    padding-top: 0px !important;
  }
  .CardDeckNav__close .menu a {
    padding: 6px !important;
  }
  .CardDeckNav__close .menu a img {
    width: 25px !important;
  }
  .CardDeckNav__title h1 {
    margin: 3px 18px !important;
  }
  nav.DesktopSubNav .DesktopSubNav_container .NavDeck {
    padding: 20px 10px 5px 10px !important;
  }
  nav.DesktopSubNav .DesktopSubNav_container .NavDeck .CardDeck {
    min-height: 300px !important;
  }
}

@media only screen and (max-width: 700px) {
  .CardDeckNav__title h1 {
    font-size: 26px !important;
  }
  .CardDeckNav__createInfomap {
    padding-top: 0px !important;
  }
  .CardDeckNav__createInfomap .button-box {
    text-align: right !important;
    line-height: 10px !important;
  }
  .CardDeckNav__createInfomap .button-box .button {
    font-size: 14px !important;
  }
  .CardDeckNav__config {
    right: 0px !important;
    left: 0px !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
  .CardDeck {
    text-align: center !important;
  }
  .CardDeck .card {
    margin-right: 0px !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

nav.DesktopSubNav .DesktopSubNav_container .NavDeck {
  padding: 20px 30px 5px 30px;
  bottom: 0px;
position: absolute;
height: 573px;
width: 100%;
background-color: white;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .CardDeck {
  overflow: auto;
height: 378px;
color:black;
padding-top: 20px;
    padding-bottom: 20px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .CardDeck a {
cursor: pointer;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card {
  color: #000;
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 20px;
  height: 220px;
  cursor: pointer;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card.disabled {
  pointer-events: none;
  background-color: #BCCCDC;
  opacity: 0.8;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card:hover {
  background-color: #102A43;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card.selected {
  background-color: #102A43;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card.selected:hover {
  background-color: #102A43;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container {
width: 100%;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .CardContainer__preview {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .CardContainer__preview img {
  width: 23px;
  margin-left: 16px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .CardContainer__edit {
  position: absolute;
  z-index: 1;
  top: 0px;
  right: 0px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .CardContainer__edit img {
  width: 23px;
  margin-right: 16px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card:hover .mapboxgl-map{
  opacity: 0.5;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card.selected:hover .mapboxgl-map{
  opacity: 0.9;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .card_map {
  width: 100%;
  border: 5px solid white;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card:hover .card_container .card_map {
  width: 100%;
  border: 5px solid #00241C;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .card_map img {
  width: 220px;
height: 160px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card.in-deck .card_container .mapboxgl-canvas {
  width: 220px !important;
  height: 160px !important;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card.selected .card_container .card_map {
  width: 100%;
  border: 5px solid transparent;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .card_map .mapboxgl-missing-css {
  display: none;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail {
  position: absolute;
  bottom: 0px;
  height: 50px;
  padding: 0px 0px 0px 10px;
  width: 100%;
  background-color: white;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer {

}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer .card_name {
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer .card_name .CardDetailContainer__container {
  padding-top:15px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer .card_name .CardDetailContainer__container img {
  width:20px;
  float: right;
  margin-right: 8px;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer .card_name .CardDetailContainer__container h3 {
  margin: 0px;
  font-size: 16px;
  width: 190px;
    text-overflow: ellipsis;
    /* height: 50px; */
    white-space: nowrap;
    overflow: hidden;
}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer .card_url {

}
nav.DesktopSubNav .DesktopSubNav_container .NavDeck .card .card_container .CardDetail .CardDetailContainer .setup {

}

.CardDeckNav {
  display: -webkit-box!important;
display: -webkit-flex!important;
display: -ms-flexbox!important;
display: flex!important;
-ms-flex-align: center;
align-items: center;
}
.CardDeckNav h1 {
  -webkit-box-flex: 1;
-webkit-flex: 1 1 auto;
-ms-flex: 1 1 auto;
flex: 1 1 auto;
  color: #102A43;
letter-spacing: -1.33px;
font-size: 46px;
margin: 30px 0px;
}

.CardDeckNav .CardDeckNav__createInfomap {
}

.CardDeckNav .CardDeckNav__close {
  position: absolute;
  right: 30px;
  top: -97px;
}

.CardDeckNav .CardDeckNav__close .menu {
  border-radius: 45px;
background-color: #4098D7;
height: 68px;
-webkit-transition: .3s ease-out;
transition: .3s ease-out;
-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    padding-top: 13px;
    cursor: pointer;
}
.CardDeckNav .CardDeckNav__close .menu:hover {
  background-color: #243B53 !important;
}


.CardDeckNav .CardDeckNav__close .menu a {
  line-height: 0px;
      padding: 19px;
}

.CardDeckNav .CardDeckNav__close .menu a img {
  width: 30px;
}
.CardDeckNav .CardDeckNav__close .menu button:focus {
  background-color:transparent;
}

.CardDeckNav .CardDeckNav__title {
      flex: 1 1 auto;
}

.CardDeckNav .CardDeckNav__config {
  position: fixed;
right: 30px;
background-color: #EEEFEE;
width: 450px;
border-radius: 9px;
padding: 5px 20px;
display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.CardDeckNav .CardDeckNav__config input {
  color: #6C7672;
  margin-bottom: 0px;
  margin-right: 25px;
}


.card-deck-tab-container {
  border-bottom: 1px solid gray;
}

.card-deck-tab-container .card-deck-tab {
      display: inline-block;
          padding: 0px 10px 0px 10px;
              cursor: pointer;
  border-bottom: 0px;
}

.card-deck-tab-container .card-deck-tab span {
  text-transform: uppercase;
  color: #829AB1;
}

.card-deck-tab-container .card-deck-tab:hover {
}

.card-deck-tab-container .card-deck-tab:hover span {
color: #243B53;
}

.card-deck-tab-container .card-deck-tab.selected {
  border-bottom: 1px solid #627D98;
}

.card-deck-tab-container .card-deck-tab.selected span {
color: #102A43;
}

@media only screen and (max-width: 700px) {
  .SidePanelContainer {
    display: none;
  }
}

li {
  list-style-type: none;
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

.GeotillerVersion {
  position: absolute;
  left: 10px;
    bottom: 13px;
  z-index: 4;
}

.SidePanelContainer.SidePanelContainer--full {
  width: 280px;
}

.SidePanelContainer {
  padding-left: 13px;
  width: 350px;
}
.SidePanelContainer__header {
  min-height: 50px;
  background-color: white;
  padding: 15px 20px;
  margin-right: 16px;
}

.SidePanelContainer__header .title {
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.SidePanelContainer__header .title img {
  width: 22px;
  margin-right: 8px;
}
.SidePanelContainer__header .title span {
  color: #777;
  font-size: 14px;
}
.SidePanelContainer__header ul {
  margin: 0px;
}
.SidePanelContainer__header ul li {
  color: #777;
  font-size: 12px;
}
.SidePanelContainer__controls {
  background: #f1f1f1;
  height: 40px;
  margin: 0;
  padding: 8px 18px;
  margin-right: 16px;
}
.SidePanelContainer__controls .SidePanelContainer__control {

}
.SidePanelContainer__controls .SidePanelContainer__control a {
  display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}
.SidePanelContainer__controls .SidePanelContainer__control a.right-align {
  float: right;
}
.SidePanelContainer__controls .SidePanelContainer__control img {
  width: 15px;
}
.SidePanelContainer__controls .SidePanelContainer__control span {
 padding-left: 10px;
}
.SidePanelContainer__feed {
  margin-right: 13px;
}
.SidePanelContainer__feed ul {
  margin: 0px;
  padding-right: 3px;
}

.header-panel {
  border-bottom: 5px solid #d4d4d4;
}

.documents-panel {
  display: -ms-flexbox;
  display: flex;
  position: fixed;
top: 0px;
left: 0px;
z-index: 1;
}
.dp-side-panel {
  width: 100%;
  padding-top: 15px;
}

.ReactModal__Content {
  width: 80%;
}

.SplitPane.vertical {
  height: auto !important;
  overflow: auto !important;
}

nav {
  background-color: #000
}
.breadcrumbs {
  display: inline-block;
  margin-left: 30px;
}
.anchor {
  margin-left: 20px;
  cursor: pointer;
}

td {
  padding-left: 20px;
}
.btn {
border-radius: 0px !important;
}

.rst__row {
  cursor: pointer;
}



.controls {
  position: absolute;
  z-index: 1;
  width: 200px;
  display:none;
}
.controls.active {
  display:block;
}


.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 0px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}



.dp-content-panel {
  background-color: white;
  height: 100%;
  width: 100%;
}

.dp-content-panel .dp-content-panel-nav {
  z-index: 1;
  position: fixed;
  right: 33px;
margin-top: 33px;
}

.dp-content-panel .dp-content-panel-body {
  width: 100%;
}

.desktop_map_container {
  height: 100%;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

.desktop_map_container.blur {
  filter: blur(2px);
}

.desktop_map_root {
  top:0;
  bottom:0;
  width:100%;
  height:100%;
  position: absolute;
left: 0;
top: 0;
}

input.dark {
  color: black;
}

.SubnavMenu {

}
.SubnavMenu.hide {
}
.SubnavMenu .hamburger-box {
  vertical-align: middle;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.9);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert {}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg{
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 25px;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

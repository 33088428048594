
.Feed {
  box-sizing: content-box;
  overflow-y: scroll;
padding: 0 20px;
width: calc(100% - 40px);
}

.Feed .Feed__mapName {
  margin-top: 10px;
font-size: 17px;
    margin-bottom: 13px;
        text-align: center;
}

.Feed .Feed__mapName .Feed__mapTitle {
  font-size: 34px;
  margin-bottom: 13px;
}

.Feed .Feed__contents {
  max-width: 935px;
  margin-left: auto;
  margin-right: auto;
  margin-top:25px;
}

.feature_marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  background-color: black;
}

nav.FeedNav {
  background-color: #fafafa;
padding: 0;
background-color: #fff;
border-bottom: 1px solid rgba(0,0,0,.0975);
position: fixed;
top: 0;
width: 100%;
z-index: 1;
-webkit-transition: height .2s ease-in-out;
transition: height .2s ease-in-out;
height: 77px;
border-top: 2px solid #edededa1;
}

nav.FeedNav .FeedNav__container {
  margin-left:auto;
  margin-right: auto;
  width: 1000px;
  display: -ms-flexbox;
    display: flex;
    display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
height: 100%;
}

nav.FeedNav .FeedNav__infomap {
flex: 0 0 auto;
}
nav.FeedNav .FeedNav__infomap h2 {
 color: black;
 margin: 0px;
 font-size: 24px;
}
nav.FeedNav .FeedNav__infomap h5 {
 color: black;
 margin: 5px 0px;
 font-size: 14px;
 color: #6c7672a1;
}

nav.FeedNav .FeedNav__import {
  flex: 2 1 auto;
  margin-left: 12px;
}

nav.FeedNav .FeedNav__logo {
flex: 0 0 auto;
}

nav.FeedNav .FeedNav__logo img {
width: 90px;
}

.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.map_container {

}

.map_root {
  top:0;
  bottom:0;
  width:100%;
  height:350px;
  position: absolute;
left: 0;
top: 0;
}

@media only screen and (max-width: 700px) {
  .DesktopNav {
    display: none;
  }
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

.DesktopNav {
  background-color: transparent;
  position: fixed;
  z-index: 4;
  box-shadow: none;
  width: 400px;
  right: 0px;
}
.DesktopNav .DesktopNav_container {
 height: 64px;
 padding: 10px;
 box-sizing: border-box;
position: relative;
width: 100%;
display: -webkit-box;
display: -moz-box;
display: -ms-flexbox;
display: -webkit-flex;
display: flex;
}

.DesktopNav .DesktopNav_container .left {
  flex: 1 1 auto;
 overflow: hidden;
-ms-flex-align: center;
align-items: center;
display: -ms-flexbox;
display: flex;
}
.DesktopNav .DesktopNav_container .left .menu {
}
.DesktopNav .DesktopNav_container .left .menu.hide {
}
.DesktopNav .DesktopNav_container .left .hamburger-box {
  vertical-align: middle;
}
.DesktopNav .DesktopNav_container .left .maps {

}
.DesktopNav .DesktopNav_container .left .maps a {
cursor: pointer;
}

.DesktopNav .DesktopNav_container .left .new {
  margin-left: 20px;
      margin-top: 5px;
}

.DesktopNav .DesktopNav_container .left .new img {
  width: 9px;
}

.DesktopNav .DesktopNav_container .left .new span {
  margin-left: 13px;
  color: black;
  text-transform: uppercase;
  font-size: 13px;
}


.DesktopNav .DesktopNav_container .left button {
  padding: 0px 3px;
}

.DesktopNav .DesktopNav_container .left button:focus {
  background-color: transparent;
}

.DesktopNav .DesktopNav_container .nav {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.DesktopNav .DesktopNav_container .right {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 32px;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  height: 48px;
    line-height: normal;
    padding: 0 4px;
    height: 48px;
    vertical-align: middle;
    white-space: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-user-select: none;
}

.DesktopNav .DesktopNav_container .right .new {
  margin: 16px 20px 12px 0px;
}

.DesktopNav .DesktopNav_container .right .new a {
  border: 0;
  letter-spacing: 0.5px;
  background-color: #4098D7;
  border-radius: 1.7rem;
line-height: 0;
padding: 12px 20px;
-webkit-transition: background-color .4s,border-color .4s;
transition: background-color .4s,border-color .4s;
box-shadow: 0 4px 6px rgba(0,0,0,.15);
}
.DesktopNav .DesktopNav_container .right .new a:active {
box-shadow: 0 1px 3px rgba(0,0,0,.15);
}
.DesktopNav .DesktopNav_container .right .new a:hover {
background-color: #243B53;
}

.DesktopNav .DesktopNav_container .right .new img {
    width: 11px;
}

.DesktopNav .DesktopNav_container .right .new span {
  margin-left: 13px;
  color: #FFF;
}


.DesktopNav .DesktopNav_container .right .bookmark {
  margin-right: 17px;
  margin-top: 10px;
}

.DesktopNav .DesktopNav_container .right .bookmark img {
  width: 22px;
}

.DesktopNav .DesktopNav_container .right .user {
  width: 34px;
height: 34px;
  border-radius: 50px;
  background-color: #FFF;
  margin-top: 7px;
}


nav.DesktopSubNav {
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid rgba(0,0,0,.0975);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out;
  height: 300px;
  z-index: 3;
}

nav.DesktopSubNav.open {
  height: 100%;
}
nav.DesktopSubNav.close {
  display: none;
}


nav.DesktopSubNav .DesktopSubNav_container {

}
nav.DesktopSubNav .DesktopSubNav_footer {
  background-color: #6C7672;
  height: 21px;
  width: 100%;
  position: fixed;
bottom: 0px;
}





nav.DesktopBookmarkView {
  background-color: #fafafa;
  padding: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0,0,0,.0975);
  position: fixed;
  width: 100%;
  z-index: 2;
  -webkit-transition: height .2s ease-in-out;
  transition: height .2s ease-in-out;
  height: 300px;
  z-index: 3;
}

nav.DesktopBookmarkView.open {
  height: 100%;
}
nav.DesktopBookmarkView.close {
  display: none;
}


nav.DesktopBookmarkView .DesktopBookmarkView_container {

}
nav.DesktopBookmarkView .DesktopBookmarkView_footer {
  background-color: #6C7672;
  height: 21px;
  width: 100%;
  position: fixed;
bottom: 0px;
}


.DesktopNav__user-dropdown.DesktopNav__user-dropdown--active {
    display: block;
}
.DesktopNav__user-dropdown {
  background-color: white;
      margin: 0;
      padding: 5px 0;
      list-style-type: none;
      display: none;
      position: absolute;
      top: 65px;
      float: right;
      right: 10px;
      font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, 0.16), 0 8px 16px rgba(27, 39, 51, 0.08);
    border-radius: 4px;
    outline: none;
        z-index: 1;
}
.DesktopNav__user-dropdown li {
  float: none;
}
.DesktopNav__user-dropdown li h5 {
  color:black;
  margin-left: 7px;
}
.DesktopNav__user-dropdown li a {
  display: block;
  padding: 5px 10px;
  white-space: nowrap;
  text-align: left;
  background-color: transparent;
  color: #2895F1;
text-decoration: none;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
cursor: pointer;
}
.DesktopNav__user-dropdown li a:hover {
  background-color: #E8F2FB;
}



.MediaGallery {
  width: 100%;
height: 100%;
background-color: #fafafa;
z-index: 2;
position: fixed;
    left: 0px;
    top: 0px;
}

#lightboxBackdrop {
  /*background: transparent !important;*/
}

#lightboxBackdrop .close_1x3s325 {
  /*display: none;*/
}

#lightboxBackdrop button:focus {
  background-color: transparent !important;
}

.MediaGallery__header .MediaGallery__closeButton {
  position: absolute;
    top: 20px;
    right: 22px;
    width: 24px;
    height: 24px;
    color: white;
}

.MediaGallery__content {

}

.MediaGallery__footer {
  position: fixed;
  bottom: 0;
  height: 90px;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.0975);
  background-color: #fafafa;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
padding: 0px 30px;
}
.MediaGallery__footer .MediaGallery__footer-item {

}
.MediaGallery__footer .MediaGallery__footer-item--desc {
  flex: 1 1 auto;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
}

.MediaGallery__footer .MediaGallery__footer-item--desc img {
  width: 40px;
}
.MediaGallery__footer .MediaGallery__footer-item--desc input {
  margin: 0px 20px
}

.MediaGallery__footer .MediaGallery__footer-item--cta {
  flex: 1 1 auto;
    text-align: right;
}

.MediaGallery__footer .button-box a.primary {
  background-color: white;
}

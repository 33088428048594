@media only screen and (max-width: 700px) {
  .MapSearch {
    width: 97%;
    left: 5px !important;
    top: 6px !important;
  }
  .MapSearch .MapSearch__search input {
    width: 100%;
    padding: 0 !important;
  }
  .MapSearch .MapSearch__search input:focus {
    width: 100% !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}



.MapSearch {
  top: 15px;
      left: 350px;
    position: absolute;
    z-index: 2;
    padding: 2px;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.15);
}

.MapSearch.MapSearch--full {
  position: static;
  top: 0px;
  left: 0px;
}

.MapSearch.MapSearch--full .MapSearch__search input {
width: 100%;
}

.MapSearch.MapSearch--full .MapSearch__search input:focus {
  width: 100%;
}

.MapSearch .MapSearch__container {
  display: -ms-flexbox;
  display: flex;
  height: 45px;
}
.MapSearch .MapSearch__container .MapSearch__accessory {
}
.MapSearch .MapSearch__container .MapSearch__accessory img {
  margin: 13px;
width: 20px;
}
.MapSearch .MapSearch__container .MapSearch__search {
  flex: 1 1 auto;
}

.MapSearch .MapSearch__search input {
  background-color: #ffffffed;
      padding: 0px 20px;
      height: 100%;
      width: 300px;
      margin-bottom: 0px;
      border-bottom: 0px;
}

.MapSearch .MapSearch__results {
  position: absolute;
background-color: white;
color: black;
width: 100%;
left: 0px;
}

.MapSearch .MapSearch__results ul {
  background-color: white;
    margin: 0px;
}

.MapSearch .MapSearch__results ul li {
  padding: 20px;
  cursor: pointer;
  width: 100%;
}

.MapSearch .MapSearch__results ul li:hover {
  background-color: #4098D7;
  color: white;
}

.MapSearch .MapSearch__mapSelect select {
      height: 100%;
      display: block;
}

.MapSearch .MapSearch__search input:focus {
  width: 500px;
  border-bottom: 0px;
}


.MiniCardDeck {
margin-top: 30px;
/*display: -ms-flexbox;*/
/* display: flex; */
/*-ms-flex-align: center;*/
/* align-items: center; */
/* text-align: center; */
}

.MiniCardDeck .MiniCardDeck__item {
  width: 286px;
    height: 50px;
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 20px;
  background: #D9E2EC;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
      cursor: pointer;
      /*display: grid;*/
}

.MiniCardDeck .MiniCardDeck__item:hover {
  background: #334E68 !important;
  color: #F0F4F8;
}

.MiniCardDeck .MiniCardDeck__item.MiniCardDeck__item--selected {
  -webkit-box-shadow: 0 2px 2px 0 rgba(41, 124, 187, 0.14), 0 3px 1px -2px rgba(41, 124, 187, 0.14), 0 1px 5px 0 rgba(41, 124, 187, 0.14);
  box-shadow: 0 2px 2px 0 rgba(41, 124, 187, 0.14), 0 3px 1px -2px rgba(41, 124, 187, 0.14), 0 1px 5px 0 rgba(41, 124, 187, 0.14);
  background: #003E6B;
  color: white;
}

.MiniCardDeck .MiniCardDeck__item.MiniCardDeck__item--disabled {
  background: black;
    color: #BCCCDC;
    opacity: 0.2;
    cursor: not-allowed;
}

.MiniCardDeck .MiniCardDeck__item .title {
  text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.MiniCardDeck.sm {
  margin-top: 30px;
  display: block;
}


.MiniCardDeck.sm .MiniCardDeck__item {
  width: 100%;
  height: 30px;
      margin-right: 20px;
      margin-bottom: 10px;
}

.MiniCardDeck.sm .MiniCardDeck__item .title {
    line-height: 30px;
    padding: 0px 10px;
    font-size: 13px;
}

.MiniCardDeck.md .MiniCardDeck__item {
  width: 286px;
  height: 50px;
      margin-right: 20px;
}

.MiniCardDeck.md .MiniCardDeck__item .title {
    line-height: 50px;
    padding: 0px 10px;
}

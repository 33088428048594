

@media only screen and (max-width: 380px) {

  .Homepage .HomepageContainer .ContentWindow {
      top: 200px !important;
  position: absolute !important;
  margin-bottom: 20px !important;
  }

  .Homepage .Navigation .Navigation__item {
    margin-right: 20px !important;
  }

  .Homepage .HomepageContainer .footer {
    background-color: #242C3B !important;
    height: 30px !important;
    bottom: 0px !important;
    position: fixed !important;
    margin: 0 !important;
  }
  .Homepage .HomepageContainer .footer p {
    margin: 4px !important;
  }

  .Homepage .HomepageContainer .HeaderWindow img {
  margin-top:40px !important;
}

  .ResourceWindow {
    width: 100% !important;
    left: 0% !important;
    margin-left: -0px !important;
    padding: 20px !important;
    top: 80px !important;
  }

  .ResourceWindow h3 {
font-size: 14px !important;
    line-height: 24px !important;
    margin-bottom: 25px !important;
  }

  .Footer {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px !important;
  }

  .Footer h5 {
    font-size: 10px !important;
    line-height: 17px !important;
  }

  .ResourceWindow button.cta {
    margin-top: 0px !important;
  }

}


@media only screen and (min-width: 380px) and (max-width: 700px) {

  .Homepage .HomepageContainer .ContentWindow {
      top: 200px !important;
  position: absolute !important;
  margin-bottom: 20px !important;
  }

  .Homepage .Navigation .Navigation__item {
    margin-right: 20px !important;
  }

  .Homepage .HomepageContainer .HeaderWindow img {
  margin-top:40px !important;
}

  .ResourceWindow {
    width: 100% !important;
    left: 0% !important;
    margin-left: -0px !important;
    padding: 20px !important;
    top: 80px !important;
  }

  .ResourceWindow h3 {
font-size: 18px !important;
    line-height: 34px !important;
  }

  .Footer {
    padding-left: 10px;
    padding-right: 10px;
        margin-bottom: 20px;

  }

  .Footer h5 {
    font-size: 12px !important;
    line-height: 20px !important;
  }

  .ResourceWindow button.cta {
    margin-top: 0px !important;
  }

}

@media only screen and (min-width: 700px) and (max-width: 770px) {

  .Homepage .HomepageContainer .ContentWindow {
      top: 400px !important;
  position: absolute !important;
  margin-bottom: 20px !important;
  }

  .Homepage .Navigation .Navigation__item {
    margin-right: 20px !important;
  }

  .Homepage .HomepageContainer .HeaderWindow img {
  margin-top:40px !important;
}

  .ResourceWindow {
    width: 100% !important;
    left: 0% !important;
    margin-left: -0px !important;
    padding: 20px !important;
    top: 80px !important;
  }

  .ResourceWindow h3 {
font-size: 26px !important;
    line-height: 50px !important;
  }

  .Footer {
    padding-left: 10px;
    padding-right: 10px;
        margin-bottom: 20px;

  }

  .Footer h5 {
    font-size: 20px !important;
    line-height: 50px !important;
  }

  .ResourceWindow button.cta {
    margin-top: 0px !important;
  }

}



@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

.Footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
      margin-bottom: 20px;

}
.Footer h5.smallblurb {
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: 100;
  color: #d8e8f3;
  font-size: 20px;
  line-height: 50px;
}

.ResourceWindow {
  padding-bottom: 100px;
  position: absolute;
  top: 230px;
  width: 900px;
  margin-lefT: auto;
  margin-right: auto;
  left: 50%;
  margin-left: -450px;
}
.ResourceWindow h3 {
  text-align: left;
  letter-spacing: 1.5px;
  font-weight: 100;
  color: #d8e8f3;
}
.ResourceWindow h3.blurb {
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: 100;
  color: #d8e8f3;
  font-size: 26px;
  line-height: 50px;
      margin-bottom: 53px;
}
.ResourceWindow h3 a {
  color: black !important;
}

.ResourceWindow button.cta {
font-size: 1.5em;
    border: 1px solid #0a558c;
    border-radius: 25px;
    font-weight: 500;
    padding: 9px 26px;
    color: #186faf;
    background-color: #fff;
    margin-top: 0px;
}
.ResourceWindow button.cta:hover {
  background-color: #0A558C;
  color: #f7f7f7;
    /* Firefox */
    -moz-transition: all 200ms ease-in;
    /* WebKit */
    -webkit-transition: all 200ms ease-in;
    /* Opera */
    -o-transition: all 200ms ease-in;
    /* Standard */
    transition: all 200ms ease-in;
}
.ResourceWindow h5 {
  text-align: left;
  letter-spacing: 2px;
  font-weight: 600;
  color: #d8e8f3;
  margin-top: 30px;
}
.ResourceWindow h5.DividerHeader {
  text-align: center;
letter-spacing: 2px;
font-weight: 600;
color: #d8e8f3;
margin-top: 200px;
margin-bottom: 51px;
}
.ResourceWindow p {
  font-weight: 400;
  color: #d8e8f3;
  font-size: 17px;
  margin-top: 2px;
  line-height: 35.5px;
  text-align: center;
}
.ResourceWindow p a {
  margin-left: 10px;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.Tabs {
  border-bottom: 1px solid #f3f2f9;
margin-top: 60px;
}

.Tabs ul {
  display: flex;
justify-content: center;
padding-bottom: 11px;
position: relative;
}

.Tabs ul li {
      margin-right: 70px;
}
.Tabs ul li a {
  box-sizing: border-box;
color: #99a5b2;
font-size: 18px;
padding-bottom: 8px;
cursor: pointer;
}
.Tabs ul li.active a {
  color: #1d242f;
    font-weight: 600;
    border-bottom: 3px solid #1d242f;
}

.Homepage {
  min-height: 100vh;
background-image: linear-gradient(#06211F, #242C3B);
background-size: cover;
-ms-flex-align: stretch;
align-items: stretch;
background-color: #fff;
display: -ms-flexbox;
display: flex;
-ms-flex-direction: column;
flex-direction: column;
-ms-flex-pack: justify;
justify-content: space-between;
width: 100%;
overflow: auto;
  text-align: center;

}

.Homepage .Navigation {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
max-width: 800px;
margin-left: auto;
margin-right: auto;
}

.Homepage .Navigation img {
  width: 120px;
/* padding-top: 25px; */
margin-top: 25px;
}

.Homepage .Navigation .Navigation__item {
  flex: auto;
  text-align: right;
  width: 200px;
  margin-top: 25px;
  font-family: 'Lato', sans-serif;
  font-weight: lighter;
}
.Homepage .Navigation .Navigation__item .login {
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: white !important;
    cursor: pointer;
    font-size: 12px;
}
.Homepage .Navigation .Navigation__item .register {
  position: relative;
background:#0A558C;
color: white;
padding: 0.46875rem 0.9375rem;
border-radius: 2px;
letter-spacing: 0.025em;
text-transform: uppercase;
margin-left: 20px;
color: white !important;
cursor: pointer;
font-size: 12px;
}
.Homepage .HomepageContainer {
  text-align: center;
}

.Homepage .HomepageContainer .HeaderBackdrop {
  margin-left: auto;
      margin-right: auto;
      background: radial-gradient(transparent, #06211F), #FFF url(/homepage/panoramic_view.jpg) no-repeat center top;
      /* height: 600px; */
    background-size: cover;
    opacity: 0.5;
}
.Homepage .HomepageContainer .HeaderBackdrop img {
  width: 150px;
  margin-top:125px;
}


.Homepage .HomepageContainer .HeaderWindow {
  margin-left: auto;
      margin-right: auto;
      position: absolute;
    top: 0px;
    width: 100%;
}
.Homepage .HomepageContainer .HeaderWindow img {
  width: 150px;
  margin-top:125px;
}

.Homepage .HomepageContainer .footer {
  margin-top: 60px;
}

.Homepage .HomepageContainer .ContentWindow {
max-width: 800px;
margin-left: auto;
margin-right: auto;
background: #eee;
-webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
padding: 20px 40px 40px 40px;
color: #2C2D30;
    top: 380px;
position: relative;
margin-bottom: 200px;
}

.Homepage .HomepageContainer .ContentWindow .ContentWindow__column {
  text-align: left;
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .date {
  text-align: right;
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .title {
  flex-basis: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 40px;
  font-weight: 900;
font-size: 30px;
color: #4a4a4a;
}

.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body {
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body .divider {
  height: 60px;
  border-bottom: 1px solid #E5E5E5;
  background: transparent;
  margin-bottom: 30px;
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body h2 {
  font-weight: 700;
  font-size: 20px;
  color: #4a4a4a;
  line-height: 25px;
  display: block;
  margin-bottom: 20px;
  margin-top: 30px;
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body img.tiny {
  width: 11px;
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body img.tinyx2 {
  width: 17px;
}
.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body img.screencap {
width: 100%;
}

.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body .anchor {
  font-weight: 700;
  font-size: 22px;
  color: #2C2D30;
  line-height: 27px;
  display: block;
  padding-bottom: 10px;
  margin-left: 0px;
  margin-bottom: 50px;
  border-bottom: 1px solid #E5E5E5;
  color: #4a4a4a;
  margin-top: 50px;
}

.Homepage .HomepageContainer .ContentWindow .ContentWindow__column .body p {
  font-size: 17.1px;
line-height: 26px;
margin-bottom: 12px;
margin-top: 0;
}

.takeaway li {
  font-size: 17.1px;
line-height: 26px;
margin-bottom: 12px;
margin-top: 0;
}

.takeaway li .bullet {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
}

.takeaway li .bullet img {
width: 25px;
}
.takeaway li .bullet span {
margin-left: 5px;
}

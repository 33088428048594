.DataPublisher {
  margin-top: 50px;
}

.DataPublisher .DataPublisher__locationTypeBox {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;

}
.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem {
flex: 1;
border-radius: 10px;
padding: 10px;
}
.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem:hover {
background-color: #ffffff54;
}

.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem.selected {
background-color: #f7f7f7;
border-radius: 5px;
border: 1px solid #6c767247;
}

.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem .DataPublisher__locationTypeItemContainer {

}

.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem.selected .DataPublisher__locationTypeItemContainer img {
 width: 90px;
}


.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem .DataPublisher__locationTypeItemContainer img {
 width: 50px;
}

.DataPublisher .DataPublisher__locationTypeBox .DataPublisher__locationTypeItem .DataPublisher__locationTypeItemContainer span {
 font-size: 14px;
 display: block;
}

.DataPublisher__dataModule {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
margin-top: 50px;
}

.DataPublisher__dataModule .DataPublisher__dataModuleItem {
  flex: 1;
  margin-left: 20px;
margin-right: 20px;
}

.DataPublisher__dataModule .DataPublisher__dataModuleItem h5 {
  color: #6c7672a1;
  margin-bottom: 20px;
}

.DataPublisher__dataModule .DataPublisher__dataModuleItemContainer {
}

.DataPublisher__dataModule .DataPublisher__dataModuleItemContainer span {
  display: block;
  font-size: 20px;
}

.DataPublisher__dataModuleConfirm {
  margin-top: 20px;
}

.DataPublisher .DataPublisher__dataTypeSelect {
  display: block;
}

.DataPublisher .DataPublisher__locationTypeSelect {
  display: block;
}


.Field__select {
  display: block;
}

.Field__input {
  display: block;
}

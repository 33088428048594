



.BookmarkView {
}


.mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.map_container {

}

.map_root {
  top:0;
  bottom:0;
  width:100%;
  height:350px;
  position: absolute;
left: 0;
top: 0;
}

.mapboxgl-canvas {
  left: 0;
}

.BookmarkView__footer {
  position: fixed;
  bottom: 0;
  height: 90px;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.0975);
  background-color: #fafafa;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
padding: 0px 30px;
}
.BookmarkView__footer .BookmarkView__footer-item {

}
.BookmarkView__footer .BookmarkView__footer-item--desc {
  flex: 1 1 auto;
}
.BookmarkView__footer .BookmarkView__footer-item--desc input {
  opacity:1;
  position:relative;
}
.BookmarkView__footer .BookmarkView__footer-item--cta {
  flex: 1 1 auto;
    text-align: right;
}

.BookmarkView__footer .button-box a.primary {
  background-color: white;
}

.BookmarkFeed {
  -webkit-box-sizing: content-box;
box-sizing: content-box;
overflow-y: scroll;
padding: 0 20px;
width: calc(100% - 40px);
}
.BookmarkFeed .BookmarkFeed__mapName {
  margin-top: 10px;
font-size: 14px;
margin-bottom: 13px;
text-align: center;
max-width: 935px;
margin-left: auto;
margin-right: auto;
}
.BookmarkFeed .BookmarkFeed__mapName h1 {
  font-size: 25px;
color: #4E5154;
text-align: left;
}
.BookmarkFeed .BookmarkFeed__contents {
  max-width: 935px;
margin-left: auto;
margin-right: auto;
}

.Bookmark {
  display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      border-radius: 10px;
}

.Bookmark .Bookmark__status {
  margin-right: 20px;
}
.Bookmark .Bookmark__status .Bookmark__status-icon {
  background-color: #ECEDF0;
  border-radius: 30px;
  width: 15px;
  height: 15px;
}
.Bookmark .Bookmark__status .Bookmark__status-icon.Bookmark__status-icon--active {
  background-color: #2AAE5A;
}

.Bookmark .Bookmark__details {

}

.Bookmark .Bookmark__details h5 {
  color: #4A4A4A;
}
.Bookmark .Bookmark__details h6 {
  color: #828485;
}

.Bookmark .Bookmark__cta {
  display: none;
  flex: 1 1 auto;
text-align: right;
}

.Bookmark:hover {
  background-color: #E5EDF4;
  color: white;
}

.Bookmark:hover .Bookmark__status .Bookmark__status-icon.Bookmark__status-icon {
  background-color: #7089B0;
}

.Bookmark:hover h5,h6{
  color: #7089B0;
}

.Bookmark:hover .Bookmark__cta {
  display: block;
}

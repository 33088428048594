.FeatureItemFeedClick {

}

.FeatureItemFeedClick.FeatureItemFeedClick--full {

}
.FeatureItemFeedClick.FeatureItemFeedClick--full .FeatureItemFeedClick__container{
  padding: 0px;
}

.FeatureItemFeedClick.FeatureItemFeedClick--full .FeatureItemFeedClick__container ul {
  margin-top: 0px;
min-height: 600px;
}
.FeatureItemFeedClick.FeatureItemFeedClick--full .FeatureItemFeedClick__container ul li {
margin-bottom: 20px;
}


.Select-input {

}

.Select-placeholder {

}

.FeatureItemFeedClick .FeatureItemFeedClick__container {
  padding: 0px;
  width: 100%;
}

.FeatureItemFeedClick .FeatureItemFeedClick__container .FeatureInfo {
  padding-left: 5px;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container {
  border-bottom: 1px solid gray;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container .FeatureItemFeedClick-tab {
      display: inline-block;
          padding: 10px;
              cursor: pointer;
  border-bottom: 0px;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container .FeatureItemFeedClick-tab span {
  text-transform: uppercase;
  color: #6C7672;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container .FeatureItemFeedClick-tab:hover {
  border-bottom: 1px solid black;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container .FeatureItemFeedClick-tab:hover span {
color: black;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container .FeatureItemFeedClick-tab.selected {
  border-bottom: 1px solid black;
}

.FeatureItemFeedClick .FeatureItemFeedClick-tab-container .FeatureItemFeedClick-tab.selected span {
color: black;
}

.FeatureItemFeedClick .FeatureItemFeedClick__filter {
  background: #6C7672;
box-shadow: 0 0 1px rgba(0, 0, 0, .11), 0 1px 2px rgba(0, 0, 0, .22);
box-sizing: border-box;
color: #fff;
-moz-osx-font-smoothing: grayscale;
-webkit-font-smoothing: antialiased;
padding: 16px 20px 24px;
position: relative;
z-index: 1;
display: none;
}

.FeatureItemFeedClick .FeatureItemFeedClick__filter .FeatureItemFeedClick__filter-container .filter-box:first-child{
  margin-bottom:15px;
}

.FeatureItemFeedClick .FeatureItemFeedClick-info {
margin-top: 25px;
    border-bottom: 2px solid rgba(0, 0, 0, .11);
    margin-left: 1px;
    margin-right: 1px;
    padding-bottom: 5px;
  }

.FeatureItemFeedClick .FeatureItemFeedClick-callout {
  float:right;
}

.FeatureItemFeedClick .FeatureItemFeedClick-callout a {
  cursor: pointer;
}

.FeatureItemFeedClick .FeatureItemFeedClick__filter .FeatureItemFeedClick__filter-container {
}


/* multiselect */

.Select--multi .Select-value {
  color: #00241C;
  background-color: #6C7672;
  border: #00241C;
}
.Select-input > input {
  outline: none !important;
}

.Select-input > input:focus {
  outline-color: transparent !important;
  outline-style: none !important;
}

.Select-menu-outer:hover {

}

@media only screen and (max-width: 700px) {
  .InfoMapSettings {
    background-color: white !important;
  }
  .InfoMapSettings__content {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .InfoMapSettings .InfoMapSettings__content {
    margin-top: 50px !important;
  }
  .UserSelection {
    position: inherit !important;
    width: 100% !important;
    top: 0px !important;
    -webkit-box-shadow: none !important;
    box-shadow: !important;
  }
  .UserSelection__search {
    padding: 10px 0px 0px 0px !important;
  }
}

@media only screen and (min-width: 700px) and (max-width: 970px) {

}

@media only screen and (min-width: 970px) and (max-width: 989px) {

}

@media only screen and (min-width: 1020px) and (max-width: 1220px) {

}

.UserSelection {
    z-index: 1;
    background-color: white;
    left: 30px;
top: 30px;
border-radius: 5px;
-webkit-transition: -webkit-box-shadow .25s;
transition: -webkit-box-shadow .25s;
-o-transition: box-shadow .25s;
transition: box-shadow .25s;
transition: box-shadow .25s, -webkit-box-shadow .25s;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.UserSelection .UserSelection__header {
padding: 0px 8px;
}

.UserSelection .UserSelection__header h3 {
  color: #57585A;
  font-size: 22px;
  margin-left: 10px;
}

.UserSelection .UserSelection__search {
padding: 10px 8px 0px 8px;
}

.UserSelection .UserSelection__search .UserSelection__search-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ECEDF0;
  border: 0px !important;
  padding: 5px 10px;
  border-radius: 10px;
  color: #A0A4A7;
}

.UserSelection .UserSelection__search .UserSelection__search-container button {
  display: inline-block;
position: relative;
color: rgba(0,0,0,.54);
font-size: 14px;
text-align: center;
text-decoration: none;
cursor: pointer;
vertical-align: bottom;
white-space: nowrap;
  height: 37px;
    line-height: 37px;
    padding: 0 16px;
    border: 1px solid rgba(0,0,0,.15);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: .1s background-color,.1s border-color,.1s color,.1s fill;
transition: .1s background-color,.1s border-color,.1s color,.1s fill;
}
.UserSelection .UserSelection__search .UserSelection__search-container button:focus {
background-color: #FFF
}


.UserSelection .UserSelection__search .UserSelection__search-container img {
width: 25px;
}


.UserSelection .UserSelection__search .UserSelection__search-container input {
  border: 0px !important;
  color: #A0A4A7;
  margin: 0px 0px 0px 10px;
}

.UserSelection .UserSelection__content {
padding: 5px 5px;
}

.UserSelection .UserSelection__content .UserSelection__content-items {

}

.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item--fail {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: black;
  padding: 0px 10px;
  border-radius: 5px;
  margin-bottom: 8px;
}
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item--fail .UserSelection__content-item-name {
margin-left: 20px;
}
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item--fail .UserSelection__content-item-invite {
flex: 1 1 auto;
text-align: right;
 }
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item--fail .UserSelection__content-item-invite button {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
  background-color: #f5f8fa;
background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
color: #182026;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
  line-height: 25px;
}
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: black;
  padding: 0px 10px;
  cursor: pointer;
  border-radius: 5px;
}
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item:hover {
  background-color: #E9F3FC
}

.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item .UserSelection__content-item-accessory {
  background-color: #ECEDF0;
      border-radius: 30px;
      width: 15px;
      height: 15px;
}
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item .UserSelection__content-item-accessory--active {
  background-color: green;
}

.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item .UserSelection__content-item-name {
margin-left: 20px;
}
.UserSelection .UserSelection__content .UserSelection__content-items .UserSelection__content-item .UserSelection__content-item-title {
  flex: 1 1 auto;
  text-align: right;
}

.UserSelection .UserSelection__footer {
text-align: right;
background-color: #F1F2F5;
}
.UserSelection .UserSelection__footer .UserSelection__footer-container {
  padding-top: 20px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-header {
      padding: 0px 8px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-header h3 {
  color: #57585A;
      font-size: 22px;
      margin-left: 10px;
      text-align: left;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items {

}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  color: black;
  padding: 30px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 8px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__footer-item-name {
margin-left: 20px;
margin-right: 20px;
line-height: 25px;
}
.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__footer-item-select {
  flex: 1 1 auto;
  text-align: right;
  line-height: 25px;
}
.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__footer-item-select select {
  display: block;
  width: 150px;
  height: 30px;
  line-height: 25px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__status {
  line-height: 25px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__status span {
  -webkit-box-shadow: 0 0 0 1px rgba(16,22,26,.4);
box-shadow: 0 0 0 1px rgba(16,22,26,.4);
background-color: #394b59;
background-image: linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0));
color: #f5f8fa;
padding: 5px 10px;
border-radius: 10px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__status button {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
  background-color: #f5f8fa;
background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
color: #182026;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 10px;
  vertical-align: middle;
  text-align: left;
  font-size: 14px;
}

.UserSelection .UserSelection__footer .UserSelection__footer-container .UserSelection__footer-items .UserSelection__footer-item .UserSelection__footer-item-close {
  width: 20px;
  line-height: 25px;
}


.mapboxgl-missing-css {
  display: none !important;
}


.InfoMapSettings {
  width: 100%;
height: 100%;
background-color: #fafafa;
z-index: 2;
position: fixed;
    left: 0px;
    top: 0px;
    overflow: auto;
    height: 100%;
}


.InfoMapSettings__content {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
  margin-bottom: 180px;
}

.InfoMapSettings__content-header {
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0,0,0,.05);

}

.InfoMapSettings__content-header img {
  width: 40px;
}

.InfoMapSettings__content-header h3 {
  color: rgba(0,0,0,.84);
  font-weight: 600;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.InfoMapSettings__content-item {
  margin-top: 60px;
}
.InfoMapSettings__content-item h5 {
  color: rgba(0,0,0,.84);
  font-weight: 600
}
.InfoMapSettings__content-item p {
  color: rgba(0,0,0,.84);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.InfoMapSettings__content-item .InfoMapSettings__content-item-input {
}
.InfoMapSettings__content-item .InfoMapSettings__content-item-input input {
}

.InfoMapSettings__content-item .InfoMapSettings__content-item-right {

}

.InfoMapSettings__content-item .InfoMapSettings__content-item-right select {
  display: block;
  color: rgba(0,0,0,.84);
  font-weight: 600
}


.InfoMapSettings__footer {
  position: fixed;
  bottom: 0;
  height: 90px;
  width: 100%;
  border-top: 1px solid rgba(0,0,0,.0975);
  background-color: #fafafa;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
padding: 0px 30px;
}
.InfoMapSettings__footer .InfoMapSettings__footer-item {

}
.InfoMapSettings__footer .InfoMapSettings__footer-item--desc {
  flex: 1 1 auto;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
}

.InfoMapSettings__footer .InfoMapSettings__footer-item--desc img {
  width: 40px;
}
.InfoMapSettings__footer .InfoMapSettings__footer-item--desc input {
  margin: 0px 20px
}

.InfoMapSettings__footer .InfoMapSettings__footer-item--cta {
  flex: 1 1 auto;
    text-align: right;
}

.InfoMapSettings__footer .button-box a.primary {
  background-color: white;
}

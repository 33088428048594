.App {
  text-align: center;
}

input:focus {
  border-bottom: 1px solid #297cbb !important;
  -webkit-box-shadow: 0 1px 0 0 #297cbb !important;
  box-shadow: 0 1px 0 0 #297cbb !important;
}

a {
  color: #4098D7 !important;
}

body {
  background-color: #f1f3f5;
  font-family: 'Roboto', sans-serif;
  /*font-family: proxima-nova, sans-serif;*/
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.LoadingPage {
  min-height: 100vh;
  background: -webkit-radial-gradient(transparent, #00241C), #FFF url(/vault-compressed.png) no-repeat center center fixed;
  background: -o-radial-gradient(transparent, #00241C), #FFF url(/vault-compressed.png) no-repeat center center fixed;
  background: radial-gradient(transparent, #00241C), #FFF url(/vault-compressed.png) no-repeat center center fixed;
  background-size: cover;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
}


@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.ReactModalPortal .close {
  float: right;
}

.ReactModalPortal .ReactModal__Overlay {
  z-index: 1;
}

.mapboxgl-ctrl-group {
  z-index: 1;
}
.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-marker {
  max-width: 40px;
  max-height: 40px;
text-align: left;
z-index: 2;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group {
  z-index: 5;
}
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out {
  width: 42px;
  height: 42px;
  z-index: 100;
}

@-webkit-keyframes bounce {
  0%       { top:1px; }
  25%, 75% { top:5px; }
  50%      { top:9px; }
  100%     {top:0;}
}

.MasterIcon.TargetProperty {
  background-color: #F0B429;
  border-radius: 20px;
  border-bottom: 3px solid #6d65648a;
}
.MasterIcon.Bounce {
  position:fixed;
  -webkit-animation:bounce 1s infinite;
}
.MasterIcon {
  background-size: cover;
  width: 40px;
  height:40px;
  cursor: pointer;
}
.MasterIcon.FlagIcon {
  background-image: url(/markers/red-flag-v2.svg);
}
.MasterIcon.Apartment {
  background-image: url(/markers/apartment-geotiller.svg);
}
.MasterIcon.Condo {
  background-image: url(/markers/condo-geotiller.svg);
}
.MasterIcon.Hotel {
  background-image: url(/markers/hotel-geotiller.svg);
}
.MasterIcon.Industrial {
  background-image: url(/markers/industrialBuilding-geotiller.svg);
}
.MasterIcon.Land {
  background-image: url(/markers/land-geotiller.svg);
}
.MasterIcon.MixedUse {
  background-image: url(/markers/mixedUse-geotiller.svg);
}
.MasterIcon.Office {
  background-image: url(/markers/office-geotiller.svg);
}
.MasterIcon.Retail {
  background-image: url(/markers/retail-geotiller.svg);
}
.MasterIcon.SingleFamily {
  background-image: url(/markers/singleFamilyHouse-geotiller.svg);
}



.notification-info {
  background-color: #297cbb !important;
}

.notification-success {
  background-color: #38BEC9 !important;
}

.notification-warning {
  background-color: #F7C948 !important;
}

.notification-error {
  background-color: #D64545 !important;
}





.button-box .button {
      box-shadow: 0 4px 6px rgba(0,0,0,.15);
  display: inline-block;
    position: relative;
    color: rgba(0,0,0,.54);
    background: rgba(0,0,0,0);
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    vertical-align: bottom;
    white-space: nowrap;
    text-rendering: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    letter-spacing: 0;
    font-weight: 500;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
    height: 3px;
    line-height: 3px;
    padding: 21px 25px;
    border: 1px solid rgba(0,0,0,.15);
    -webkit-border-radius: 4px;
    border-radius: 4px;
    margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  }

  .button-box .button:active {
        box-shadow: 0 1px 3px rgba(0,0,0,.15);
      }

.button-box .button.secondary {
  border: 0px;
}

.button-box .button.primary{
  border: 0px;
color: white !important;
    background-color: #297cbb !important;
}

.button-box .button.vivid{
  border: 0px;
color: #8D2B0B !important;
    background-color: #F7C948 !important;
}

.button-box .button.vivid1{
  border: 0px;
color: #Fefefe !important;
    background-color: #C93C3D !important;
}

.button-box .button.white{
background-color: #f5f5f5;
}

.button-box .button.primary.disabled{
  opacity: 0.4;
  color: #747272;
  pointer-events: none;
}

.button-box .button.cancel {
  border: 1px solid rgba(0,0,0,.15) !important;
  color: rgba(0,0,0,.54) !important;
}

.button-box .button.danger {
  border: 1px solid rgba(0,0,0,.15) !important;
  color: rgba(0,0,0,.54) !important;
}

.button-box .button.danger.disabled {
  border: 1px solid rgba(0,0,0,.15) !important;
  color: rgb(136 136 136 / 54%) !important;
  background-color: #a5a5a563 !important;
}


.button-box .button.mini {
  height: 25px;
font-size: 14px;
line-height: 23px;
font-weight: 600;
}

.button-box .inline-input {
  display: inline-block;
  width: 280px;
position: relative;
color: rgba(0,0,0,.54);
background: rgba(0,0,0,0);
font-size: 16px;
text-decoration: none;
cursor: pointer;
vertical-align: bottom;
white-space: nowrap;
text-rendering: auto;
-webkit-user-select: none;
-moz-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-box-sizing: border-box;
box-sizing: border-box;
font-family: medium-content-sans-serif-font,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
letter-spacing: 0;
font-weight: 400;
font-style: normal;
text-rendering: optimizeLegibility;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
-moz-font-feature-settings: "liga" on;
height: 37px;
line-height: 37px;
padding: 0 16px;
border: 1px solid rgba(0,0,0,.15);
border-radius: 4px;
margin-right: 8px;
vertical-align: middle;
border-radius: 4px;
line-height: 37px;
padding: 0 16px;
height: 37px;
margin-bottom:0px;
}

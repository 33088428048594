.FeatureList__LifecyleGroup {
}
.FeatureList__LifecyleGroup:not(:first-child),.FeatureList__LifecyleGroup:not(:first-child):last-child {
  margin-top: 20px;
}

.FeatureList__LifecyleGroup .FeatureList__LifecyleGroupTitle {
  font-size: 16px;
line-height: 21px;
font-weight: 400;
padding-bottom: 13px;
color: rgba(0, 0, 0, 0.7);
padding-left: 17px;
border-top: 1px solid #E1E1E1;
padding-top: 12px;
background-color: #EFF1F3;
    margin-right: 3px;
}

.Photos {
}

.Photos .Photos--tiny{
  width: 33%;
}

.Photos .Photos__PhotoRow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.Photos .Photo {
  flex-grow: 1;
  min-width: 100px;
}

.Photos .Photo:first-child .Photo__container {
  padding: 0px 2px 2px 2px;
}

.Photos .Photo:last-child .Photo__container {
  padding: 0px 0px 2px 2px;
}

.Photos .Photo .Photo__container {
  background-color: #efefef;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0px 2px 2px 2px;
}

.Photos .Photo .Photo__container img {
  width: 100%;
      height: 100%;
}

.feature-panel li {
    background-color: #fff;
border: 0;
box-shadow: 0 0 1px rgba(0, 0, 0, .11), 0 1px 2px rgba(0, 0, 0, .22);
cursor: pointer;
    border-left: 6px solid #486581;
}

.feature-panel li.create {
  border: 2px solid #E6C070;
      margin-bottom: 14px;
      margin-top: 14px;
}

.feature-panel li.create .header {
  display: -ms-flexbox!important;
      display: flex!important;
      margin-bottom: 15px;
}

.feature-panel li.create .input-field-box {
  margin-bottom: 20px;
}

.feature-panel li.create .input-field-box input {
padding-left: 6px;
}

.feature-panel li.create .button-field-box button {
width: 100%;
}

.feature-panel li.create .header h5 {
  -ms-flex: 1 1 auto;
flex: 1 1 auto;
margin: 0px;
}

.feature-panel li.create .header .close {
  -ms-flex: 0 1 auto;
flex: 0 1 auto;
}

.feature-panel li.create .header .close img {
  width: 25px;
}


.feature-panel li.create .feature select {
  display: block;
}


.feature-panel li:hover {
      box-shadow: 0 0 3px rgba(0, 0, 0, .13), 0 3px 6px rgba(0, 0, 0, .26);
}

.feature-panel li .feature {
  padding: 10px 5px 10px 5px;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
}

.feature-panel li .feature.selected {
  background-color: #F0F4F8
}
.feature-panel li .feature:hover {
  background-color: #F0F4F8
}

.feature .feature__avatar {
  height: 50px;
}
.feature .feature__avatar a {
  width: 40px;
    height: 40px;
    background-color: #fafafa;
    border-radius: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    overflow: hidden;
    position: relative;
}
.feature .feature__avatar img {
  height: 100%;
width: 100%;
}

.feature .feature__item {
  margin-left: 5px;
  display: -ms-flexbox;
display: flex;
-ms-flex-align: center;
align-items: center;
-webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.feature .feature__item img {
  width: 25px;
}

.feature .feature__item .title {
  font-size: 1.0em;
  margin-left: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}
.feature .feature__item .coords {
      font-size: 0.9em;
      color: #6C7672;
}

.feature .feature__item .lifecycle {
  font-size: 0.9em;
  color: #6C7672;
}
